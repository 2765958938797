<template>
  <v-container fluid grid-list-xl class="progress-container">
    <v-layout align-center justify-center column>
      <v-flex xs12 sm12 md12>
        <!-- <a href="https://enercred.com.br" target="_blank" alt="Enercred - Energia Limpa e Barata"> -->
          <img
            src="@/assets/logo.svg"
            width="180px"
            style="padding: 0px 0"
            alt="Enercred - Energia Limpa e Barata"
          />
        <!-- </a> -->
      </v-flex>
      <v-flex v-if="loading" align-center justify-center row>
        <v-progress-circular :size="40" :width="4" color="success" indeterminate></v-progress-circular>
      </v-flex>
      <v-flex
        xs12
        sm6
        md6
        v-if="!loading && user.id && !hasCard && !boletoSelected"
        class="align-center justify-center row fill-height"
      >
        <h2>{{firstName}}</h2>
        <p class="subheader">
          É um imenso prazer te-lo como nosso cliente e ajudá-lo a economizar na conta de luz, 
          <strong>escolha agora a forma de pagamento</strong> para <strong>garantir seu desconto</strong>, 
          que deve chegar entre maio e junho.
        </p>
        <v-layout align-center justify-center row fill-height justify-space-around>
          <v-flex
            xs6
            sm6
            md6
            v-if="!loading"
            align-self-center
            class="botao-form-pagto"
            pr-2
            @click="updateBoleto(user.id)"
          >
            <!-- <a href="#" > -->
              <img
              src="@/assets/icons/boleto.png"
              width="90px"
              style="padding: 14px 5px"
              alt="Enercred - Energia Limpa e Barata"
              />
              <p>QUERO BOLETO</p>
            <!-- </a> -->
          </v-flex>
          <v-flex
            xs6
            sm6
            md6
            v-if="!loading"
            align-self-center
            class="botao-form-pagto"
            @click="goToCard(email)"
          >
            <img
            src="@/assets/icons/debit-card.svg"
            width="90px"
            style="padding: 5px"
            alt="Enercred - Energia Limpa e Barata"
            />
            <p>CADASTRAR CARTÃO DE CRÉDITO</p>
          </v-flex>
        </v-layout>
        <p class="details">Você começa a pagar após receber o seus créditos na conta de energia.</p>
      </v-flex>
      <v-flex
        xs12
        sm6
        md6
        v-if="!loading && boletoSelected"
        class="align-center justify-center row fill-height"
      >
        <img
          src="@/assets/icons/boleto.png"
          width="180px"
          style="padding: 5px"
          alt="Enercred - Energia Limpa e Barata"
        />
      </v-flex>
      <v-flex
        xs12
        sm6
        md6
        v-if="!loading && boletoSelected"
        class="align-center justify-center row fill-height"
      >
        <h2>{{firstName}}</h2>
        <p class="subheader">
          Você optou pela forma de pagamento
          <strong>boleto</strong> para pagar por sua
          <strong>energia limpa</strong>,
          seus créditos devem chegar entre
          <strong>maio ou junho</strong>.
        </p>
        <p class="details">Enviamos um email com detalhes sobre sua economia.</p>
      </v-flex>
      <v-flex
        xs12
        sm12
        md12
        v-if="!loading && user.id && hasCard"
        class="align-center justify-center row fill-height"
      >
        <h2>{{firstName}}</h2>
        <p
          class="subheader"
        >Verificamos que você já possui um cartão de crédito cadastrado, deseja mudar sua forma de pagamento para boleto?</p>
        <v-btn
          :loading="loadingButton"
          :disable="loadingButton"
          color="success"
          @click="updateBoleto(user.id)"
        >Sim</v-btn>
      </v-flex>
      <v-flex
        v-if="!loading && !user.id"
        xs12
        sm12
        md12
        class="align-center justify-center row fill-height"
      >
        <h2>Usuário não identificado!</h2>
        <p>
          Por favor, entre em contato entre em contato com nosso time pelo telefone
          <a
            href="tel:+553135003614"
            target="_blank"
          >(31) 3500-3614</a>
          , pelo whatsapp
          <a
            href="https://wa.me/553536291633"
            target="_blank"
          >(35) 3629-1633</a>
          ou email
          <a
            href="mailto:contato@enercred.com.br?subject=Por que devo escolher a forma de Pagamento"
            target="_blank"
          >contato@enercred.com.br.</a>
        </p>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import { getUser, updateUser } from "../../services/firebase/user"
import { getIP } from "../../services/getIP"
import { saveAnalytics } from "../../services/save_analytics"
// import axios from 'axios';
export default {
  data() {
    return {
      loading: true,
      loadingButton: false,
			hasCard: false,
			isProd: false,
			user: {},
			ipinfo: {},
      boletoSelected: false,
      utm_source: '',
      utm_medium: '',
      utm_campaign: '',
    }
  },
  created() {
		// const url = 'http://ipinfo.io/json?token=dfd25b664a2884'
		// axios.get(url).then(response => {
		// 	this.ipinfo = response.data
		// 	console.log(response.data)
		// })
    this.isProd = process.env.NODE_ENV === "production"
    if(this.isProd) {
      getIP()
        .then(response => {
          this.ipinfo = response.data
          console.log(response.data)
        })
        .catch(error => {
          console.error('Erro ao recuperar IP do usuário. Erro: ', error)
        })
    }
    if(this.$route.query.utm_source) this.utm_source = this.$route.query.utm_source || ''
    if(this.$route.query.utm_medium) this.utm_medium = this.$route.query.utm_medium || ''
    if(this.$route.query.utm_campaign) this.utm_campaign = this.$route.query.utm_campaign || ''

    if (this.$route.query.id) {
      getUser(this.$route.query.id)
        .then(user => {
          if (user.exists) {
            user = {
              id: user.id,
              ...user.data()
            }
            this.user = user

            if (user.card_default) {
              this.hasCard = true
            }
            this.loading = false
          } else {
            // doc.data() will be undefined in this case
						console.error("Erro: Usuário não identificado!")
						this.loading = false
          }
        })
        .catch(error => {
          console.error("Erro: Usuário não identificado! ", error)
          this.loading = false
        })
    } else {
      this.loading = false
    }
  },
  methods: {
    updateBoleto(id) {
      this.loading = true
      const data = {
        boleto_accepted_at: new Date(),
        boleto_accepted_by: this.utm_medium || 'undefined',
				payment_type: "boleto",
				boleto_ipinfo: this.ipinfo
      }
      updateUser(id, data)
        .then(user => {
          if (user.exists) {
            user = {
              id: user.id,
              ...user.data()
            }
						this.user = user
						if (this.isProd) {
							const analytics = {
								eventCategory: "onboard",
								eventAction: "ACCEPT_BOLETO",
								eventLabel: "ACCEPT_BOLETO_" + this.utm_medium.toUpperCase(),
								value: user.email,
							}
							saveAnalytics(analytics)
            }
            this.hasCard = false
            this.loading = false
            this.boletoSelected = true
          } else {
						console.error("Erro ao atualizar o usuário!")
						this.loading = false
          }
        })
        .catch(error => {
          console.error("Erro ao atualizar o usuário! ", error)
          this.loading = false
        })
    },
    goToCard(email) {
      let url = "/cadastrar/cartao?email=" + email
      if (this.utm_source) url = url + "&utm_source=" + this.utm_source
      if (this.utm_medium) url = url + "&utm_medium=" + this.utm_medium
      if (this.utm_campaign) url = url + "&utm_campaign=" + this.utm_campaign
      this.$router.push(url)
    }
  },
  computed: {
    firstName() {
      if (!this.user.name) return ""
      else return this.user.name.split(" ")[0]
    },
    email() {
      return this.user.email
    }
  }
}
</script>
<style scoped>
.progress-container {
  height: 100vh;
  background: #fff;
}
strong {
  font-weight: 800;
  font-size: 16px;
}
.subheader {
  font-size: 16px;
  color: #333;
}
p {
  color: #666;
  font-size: 14px;
}
.details {
	color: #1fb440 !important;
  padding-top: 10px;
}
h2 {
  font-weight: 900 !important;
  font-size: 32px !important;
}
.botao-form-pagto {
  border: 1px solid #333; 
  border-radius: 10px;
  max-width: 140px;
  height: 200px;
  max-height: 200px;
  text-align: center;
  vertical-align: middle;
}
</style>